import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout'


const BlogPage = ({ data }) => {
const sorted = data.allMarkdownRemark.edges.sort((a, b) => a.node.frontmatter.number - b.node.frontmatter.number)
return (
  <Layout>
    <div className='info-container'>
      <h1>Latest Posts</h1>
      {sorted.map(post => (
        <div key={post.node.id}>
          <h3>{post.node.frontmatter.title}</h3>
          <small>Posted by {post.node.frontmatter.author} on {post.node.frontmatter.date}</small>
          <br/>
          <br/>
          <small><strong><Link className='btn' to={post.node.frontmatter.path} style={{ textDecoration: 'none' }}>
            Read More
            </Link></strong></small>
          <br/>
          <br/>
          <hr/>
        </div>
      ))}
    </div>
  </Layout>
  );
}
  export const pageQuery = graphql`
    query BlogIndexQuery {
      allMarkdownRemark {
        edges {
          node {
            id
            frontmatter {
              path
              title
              date
              author
              number
            }
          }
        }
      }
    }
  `

export default BlogPage;